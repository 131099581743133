import { Validator } from 'simple-vue-validator';

export default class CheckYoutubeUrl {
  constructor(youtubeUrl, $i18n) {
    this.youtubeUrl = youtubeUrl;
    this.$i18n = $i18n;
  }

  get isValidFunc() {
    const validationFunc = async () => {
      if (Validator.isEmpty(this.youtubeUrl)) {
        return;
      }
      const p =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      const matches = this.youtubeUrl.match(p);
      if (matches) {
        console.log('valid');
        return;
      }
      console.log('ga valid');
      return this.$i18n.t('errors.youtube.invalid');
    };

    return validationFunc;
  }
}
