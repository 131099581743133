<template>
  <div class="hide--in-mobile listing--form-popover">
    <img src="@/assets/img/icons/info.svg" class="info--icon" alt="Info" />
    <div class="listing--form-popover-content">
      <div class="row">
        <div class="col" v-html="content"></div>
        <div class="col-auto">
          <i class="icon icon--ideas icon--md"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    content: {
      required: true,
      type: String,
      default: 'No Content...',
    },
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.listing--form-popover {
  position: relative;
}
.listing--form-popover-content {
  visibility: hidden;
  position: absolute;
  right: -412px;
  top: 0;
  z-index: 1;
  width: 400px;
  background-color: $color-white;
  border-radius: 16px;
  border: solid 1px $color-gray-1;
  padding: 12px 16px;
  font-family: Poppins;
  box-shadow: $box-shadow;
  text-align: left;
  opacity: 0;
  transition: opacity 0.3s;
}
.listing--form-popover:hover .listing--form-popover-content {
  visibility: visible;
  opacity: 1;
}
</style>
